import { keys } from 'frr-web/lib/util'
import { PartnerFrontendConfiguration, UserLanguage } from '../types/frontend.types'
import { AppConfig } from '../types/app.types'

export const i18nLang: Record<UserLanguage, string> = {
  [UserLanguage.DE]: UserLanguage.DE as unknown as string,
  [UserLanguage.FR]: UserLanguage.FR as unknown as string,
  [UserLanguage.EN]: UserLanguage.EN as unknown as string,
  [UserLanguage.IT]: UserLanguage.IT as unknown as string,
  [UserLanguage.DEV]: 'cimode',
}

const languageToLabel: Record<UserLanguage, string> = {
  [UserLanguage.DE]: 'formFields.language.options.german',
  [UserLanguage.FR]: 'formFields.language.options.french',
  [UserLanguage.IT]: 'formFields.language.options.italian',
  [UserLanguage.EN]: 'formFields.language.options.english',
} as Record<UserLanguage, string>

export const getLanguageLabel = (appConfig: AppConfig) =>
  ['dev', 'test'].includes(appConfig.app.whitelabelEnvironment)
    ? ({ ...languageToLabel, DEV: UserLanguage.DEV } as Record<UserLanguage, string>)
    : languageToLabel

export const getLanguageOptions = ({
  appConfig,
  configuration,
  options = { includeDev: true },
}: {
  appConfig: AppConfig
  configuration?: PartnerFrontendConfiguration
  options?: { includeDev?: boolean }
}): Array<{
  label: string
  value: UserLanguage
}> =>
  keys(getLanguageLabel(appConfig))
    .filter((k) => options.includeDev || k !== UserLanguage.DEV)
    .filter((k) => !(configuration?.otherConfig.disableLanguages || []).includes(k))
    .map((k) => ({
      value: k,
      label: getLanguageLabel(appConfig)[k],
    }))

export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])[a-zA-Z0-9]$/

export const PHONE_REGEX = /^0?7[0-9]{1}\s?[0-9]{3}\s?[0-9]{2}\s?[0-9]{2}$/
export const PHONE_REGEX_LICHTENSTEIN = /^[0-9-]{7,12}$/
export const PHONE_REGEX_INTERNATIONAL = /^[0-9-]{9,12}$/

export const PHONE_REGEX_UNIVERSAL =
  /^(0|0041|\+41)\d{9}$|^(0|0049|\+49)1(?:6[023]|7\d)\d{7,8}$|^(0|0049|\+49)15[0-25-9]\d{8}$|^(0|0043|\+43)6(?:5[0-3579]|6[013-9]|[7-9]\d)\d{4,10}$|^(0|0039|\+39)3[1-9]\d{8}$|3[2-9]\d{7}$|^(0|0033|\+33)\d{9}$|^(0|00423|\+423)7(\d{2})(\d{2})(\d{2})$|^(0|00423|\+423)6(\d{2})(\d{3})(\d{3})$/

// At least one digit
export const ZIP_REGEXP = /(?=.*\d)/
export const SWISSZIP_REGEXP = /^(?:[1-9]\d{3})$/ /* /^\d{4}$/ */
export const LICHTENSTEIN_ZIP_LIST = [
  '9485',
  '9486',
  '9487',
  '9488',
  '9489',
  '9490',
  '9491',
  '9492',
  '9493',
  '9494',
  '9495',
  '9496',
  '9497',
  '9498',
]

export enum ZeroCountryOptions {
  CH = '+41',
  DE = '+49',
  AT = '+43',
  FR = '+33',
  IT = '+39',
  LI = '+423',
}
export const ZERO_COUNTRY_CODE_OPTIONS = Object.values(ZeroCountryOptions).map((countryCode) => ({
  name: countryCode,
  value: countryCode,
}))

export const MINIMUM_VALID_DATE = '1899-12-31'

//repleaced on 6 Jan 22: export const NAME_REGEXP = /^[A-Za-zÀ-ž ]+$/
export const NAME_REGEXP = /^[A-Za-zÀ-ž- '`.]+$/

export const LAST_NAME_REGEXP = /^[A-Za-zÀ-ž- '`._]+$/

//repleaced on 20 Jun 23: export const CITY_REGEXP = /^[A-Za-zÀ-ž- '()`.]+$/
export const CITY_REGEXP = /^[A-Za-zÀ-ž- '()`./]+$/

//repleaced on 6 Jan 22: export const NAME_REGEXP = /^[A-Za-zÀ-ž ]+$/
export const STREET_NAME_REGEXP = /^[A-Za-zÀ-ž0-9- '`.]+$/

export const STREET_NUMBER_REGEXP = /^[a-zA-Z0-9 ]*$/

export const MINIMUM_VALID_AGE_START_WORK = 13
export const ADULT_AGE = 18
