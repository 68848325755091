import { PatchUpdates } from '../data/api/api.types'
import {
  BobPartnerApp_DTOs_PartnerVariantFrontendConfigurationDTO,
  BobProductsApp_DTOs_ExternalView_ActionTypeEnum,
  BobProductsApp_DTOs_ExternalView_UserflowViewForFinancingDTO,
  BobProductsApp_DTOs_ExternalView_ActionIdentifierEnum,
  BobProductsApp_DTOs_ExternalView_PartnerAccountIdentityInfoDTO,
} from '../data/api/openapi'
import { BobProductsApp_BusinessServices_States_ExternalViews_ActionTypeEnum } from '../data/api/openapiPartnerOnboarding'
export {
  BobProductsApp_DTOs_ExternalView_ActionIdentifierEnum as ActionIdenitifier,
  BobProductsApp_DTOs_ExternalView_ActionTypeEnum as ActionType,
  BobBaseApp_AppIdentifierEnum as AppIdentifier,
  BobRetailApp_EntityModels_BobSalesChannelEnum as BobSalesChannel,
  BobDocumentsApp_DocumentTypeEnum as DocumentType,
  BobBaseApp_EntityModels_BobIdProviderEnum as IdentityProvider,
  BobBaseApp_OnboardingChannelEnum as OnboardingChannel,
  BobBaseApp_OnboardingChannelVariantEnum as OnboardingChannelVariant,
  BobProductsApp_BusinessServices_States_ExternalViews_StatusLevelEnum as StateLevel,
  // DO NOT USE (For testing only)
  BobProductsApp_BusinessServices_States_ExternalViews_StateEnum as BackendState,
} from '../data/api/openapi'

export enum PartnerOnboardingChannel {
  PARTNER_ONBOARDING = 'PARTNER_ONBOARDING',
}

export type ProductSettingsT = BobPartnerApp_DTOs_PartnerVariantFrontendConfigurationDTO & {
  isPartnerOnboarding: boolean
  partnerOnboardingClientCtx?: string
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum MartialStatus {
  Single = 'Single',
  Married = 'Married',
  RegisteredPartnership = 'RegisteredPartnership',
  Separated = 'Separated',
  Divorced = 'Divorced',
  Widowed = 'Widowed',
}

export enum ResidentPermit {
  B = 'B',
  C = 'C',
  G = 'G',
  L = 'L',
  Other = 'Other',
}

export enum EmploymentRelationship {
  Employed = 'Employed',
  InTraining = 'InTraining',
  NotWorking = 'NotWorking',
  Retired = 'Retired',
  SelfEmployed = 'SelfEmployed',
  Temporary = 'Temporary',
}

export enum EmploymentType {
  Permanent = 'PERMANENT',
  Temporary = 'TEMPORARY',
}

export enum EmploymentWorkTime {
  FullTime = 'FullTime',
  Partial = 'Partial',
}

export const mapEmploymentWorktimeToType: {
  [k in EmploymentWorkTime]: EmploymentType
} = {
  [EmploymentWorkTime.FullTime]: EmploymentType.Permanent,
  [EmploymentWorkTime.Partial]: EmploymentType.Temporary,
}

export enum SalaryType {
  Fixed = 'Fixed',
  Hourly = 'Hourly',
  Provision = 'Provision',
}

export enum OccupancyType {
  RentedApartment = 'RentedApartment',
  HomeOwnership = 'HomeOwnership',
  PensionHotelSublease = 'PensionHotelSublease',
  ApartmentEmployer = 'ApartmentEmployer',
  WithParents = 'WithParents',
}

export enum LivingType {
  Cohabitation = 'Cohabitation',
  Single = 'Single',
  SingleParentsWithoutCommunity = 'SingleParentsWithoutCommunity',
  RegisteredPartnership = 'RegisteredPartnership',
  SingleParentsWithCommunity = 'SingleParentsWithCommunity',
  SingleWithSupportDuties = 'SingleWithSupportDuties',
}

export enum LoanType {
  Lease = 'Lease',
  PrivateLoan = 'PrivateLoan',
  CreditCard = 'CreditCard',
}

export enum TransportType {
  Car = 'Car',
  Motorcycle = 'Motorcycle',
  Public = 'Public',
  Bike = 'Bike',
  Walk = 'Walk',
}

export enum TwelthSalaryType {
  X12 = '12',
  X13 = '13',
}

export type FileObject = {
  fileUpload: File | null
  fileUpload2: File | null
  fileUpload3: File | null
  fileUpload4: File | null
  fileUpload5: File | null
}

// ---------------------------
// Financing object
// ---------------------------

export type FinancingObjectBE =
  BobProductsApp_DTOs_ExternalView_UserflowViewForFinancingDTO

export type FinancingAddress = FinancingObjectBE['customerInfo']['addresses'][0]
export type FinancingObject = Omit<
  BobProductsApp_DTOs_ExternalView_UserflowViewForFinancingDTO,
  'customerInfo' | 'financingInfo'
> & {
  customerInfo: Omit<FinancingObjectBE['customerInfo'], 'identity'> & {
    previousAddress: FinancingAddress
    currentAddress: FinancingAddress
    identity: Omit<FinancingObjectBE['customerInfo']['identity'], 'partnerAccountIdentityInfo'> & {
      partnerAccountIdentityInfo: BobProductsApp_DTOs_ExternalView_PartnerAccountIdentityInfoDTO
    }
  }
  computedMonthlyRate: string
  financingInfo: Omit<FinancingObjectBE['financingInfo'], 'amount' | 'duration'> & {
    amount: number | null
    duration: number | null
  }
  fileObj: {
    fileUpload: File | null
    fileUpload2: File | null
    fileUpload3: File | null
    fileUpload4: File | null
    fileUpload5: File | null
  }

  stateLevelInfo: FinancingObjectBE['stateLevelInfo'] & {
    isShortTerm: boolean
  }
}

export enum ActionValueIdentifierType {
  BOOLEAN = 'bool1',
  BOOLEAN_2 = 'bool2',
  NUMBER = 'number1',
  STRING = 'string1',
}

export type ActionValueListItem = {
  path: string
  operation: string
  valueIdentifier: ActionValueIdentifierType
  valueType: string
}

export type StateLevelInfoActionType = {
  actionIdentifier: BobProductsApp_DTOs_ExternalView_ActionIdentifierEnum
  type: BobProductsApp_DTOs_ExternalView_ActionTypeEnum
  updates: PatchUpdates
}

// export type StatusList = FinancingObjectBE['stateList']

// --------------------------
// Pre-financing object
// --------------------------

export type PreFinancingObject = {
  variantId: string
  amount: number
  duration: number
  orderReference: string | null
  agentCode: string | null
  hasPpi: boolean
  isAgePolicy: boolean
  isResidencyPolicy: boolean
  isOccupancyPolicy: boolean
  isDebtsPolicy: boolean
  isBobPolicyAccepted: boolean
  isPartnerPolicyAccepted: boolean
  isNationalityPolicy: boolean
  interestRate: number
  financingPartner: string
}
